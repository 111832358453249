import { getImgData } from '@/utils/images';

import {
  AiCare,
  CardGrid,
  Cta,
  LogoCarousel,
  LogoGrid,
  ImageLinkGrid,
  Platform,
  QuotesCarousel,
  StatGrid,
  TestimonialsCarousel,
  Table,
} from '@swordhealth/ui-corporate';
import ContentLinks from '@/components/v2/ContentLinks';
import Counter from '@/components/v2/Counter';
import PanelList from '@/components/v2/PanelList';
import DefaultCardGrid from '@/components/v2/DefaultCardGrid';
import IconCardGrid from '@/components/v2/IconCardGrid';
import CaseStudies from '@/components/v2/CaseStudies';
import MediaContainer from '@/components/v2/MediaContainer';
import Calculator from '@/components/v2/Calculator';
import RichContent from '../RichContent';

export default function DynamicContent({ content, locale = 'en', settings, updatedAt }) {
  if (!content) {
    console.error("Please provide a 'content' property to the DynamicContent");
    return null;
  }

  return (
    <>
      {content.map(({ __component, ...item }, index) => {
        const id = `content-${index}`;

        switch (__component) {
          case 'card-list.whitepaper-content-link':
          case 'card-list.webinar-content-link':
          case 'card-list.blog-content-link':
          case 'card-list.case-study-content-link':
          case 'card-list.clinical-study-content-link':
            return (
              <ContentLinks
                {...item}
                key={id}
                id={id}
                component={__component}
                defaultImage={getImgData(settings?.seo?.image)}
              />
            );
          case 'card-list.image-link-grid':
            return <ImageLinkGrid {...item} key={id} id={id} />;
          case 'card-list.default-card-grid':
            return <DefaultCardGrid {...item} key={id} id={id} />;
          case 'card-list.icon-card-grid':
            return <IconCardGrid {...item} key={id} id={id} />;
          case 'card-list.solution-grid':
            return (
              <CardGrid
                {...item}
                columnCount={parseInt(item.columnCount)}
                cardType="solution"
                cardList={item.cardList.map((card) => ({
                  ...card,
                  solution: card?.solution?.slug,
                }))}
                key={id}
                id={id}
              />
            );
          case 'card-list.stat-grid':
            return <StatGrid {...item} key={id} id={id} />;
          case 'generic.counter':
            return <Counter {...item} key={id} id={id} />;
          case 'generic.panel-list':
            return <PanelList {...item} key={id} id={id} />;
          case 'generic.cta':
            return <Cta {...item} key={id} id={id} />;
          case 'generic.case-study':
            return <CaseStudies {...item} key={id} id={id} />;
          case 'generic.media-container':
            return <MediaContainer {...item} key={id} id={id} />;
          case 'generic.calculator':
            return <Calculator {...item} key={id} id={id} />;
          case 'logo.logo-carousel':
            return (
              <LogoCarousel
                {...item}
                logoList={item.logoList.map(({ logoBlack }) => logoBlack)}
                key={id}
                id={id}
              />
            );
          case 'logo.logo-grid':
            return (
              <LogoGrid
                {...item}
                logoList={item.logoList.map(({ logoBlack }) => logoBlack)}
                key={id}
                id={id}
              />
            );
          case 'quote.client-quote-carousel':
            return (
              <QuotesCarousel
                {...item}
                quoteList={item.quoteList?.map(({ logo, ...quote }) => ({
                  ...quote,
                  image: logo?.logoColor ? logo?.logoColor : logo?.logoBlack,
                }))}
                key={id}
                id={id}
                variant="client"
              />
            );
          case 'quote.member-quote-carousel':
            return <QuotesCarousel {...item} key={id} id={id} variant="member" />;
          case 'quote.member-testimonial-carousel':
            return <TestimonialsCarousel {...item} key={id} id={id} />;
          case 'solutions.ai-care':
            return <AiCare {...item} key={id} id={id} />;
          case 'solutions.platform':
            return item?.solutionList?.length ? (
              <Platform
                {...item}
                solutionList={item.solutionList.map(({ name, slug }) => ({
                  href: `/solutions/${slug}`,
                  solution: slug,
                  label: name,
                }))}
                key={id}
                id={id}
              />
            ) : null;
          case 'generic.table':
            return <Table {...item} key={id} id={id} />;
          case 'generic.rich-content':
            return <RichContent {...item} key={id} id={id} updatedAt={updatedAt} locale={locale} />;
          default:
            return null;
        }
      })}
    </>
  );
}
