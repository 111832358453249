import { useMemo } from 'react';

import { Paragraph, RichText, SectionWrap } from '@swordhealth/ui-corporate';

import { getDictionary } from '@/lib/i18n/dictionaries/dictionary';

import styles from './styles.module.css';

export default function RichContent({ theme, content, displayUpdatedAt, locale, updatedAt }) {
  const label = useMemo(async () => {
    if (!updatedAt) return '';

    const dictionary = await getDictionary(locale);

    return `${dictionary.updatedAt} ${new Intl.DateTimeFormat(locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).format(new Date(updatedAt))}`;
  }, [locale, updatedAt]);

  return (
    <SectionWrap xAlign="left" containerSize="sm" theme={theme}>
      <RichText addTags={['iframe']} addAttrs={['allowfullscreen']}>
        {content}
      </RichText>
      {displayUpdatedAt && updatedAt && (
        <Paragraph as="footer" className={styles.timestamp}>
          {label}
        </Paragraph>
      )}
    </SectionWrap>
  );
}
