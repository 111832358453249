'use client';

import { useState } from 'react';
import { PanelList as UIPanelList } from '@swordhealth/ui-corporate';

import PanelMedia from './PanelMedia';

export default function PanelList({ panelList, scrollBehavior, ...props }) {
  const [activePanels, setActivePanels] = useState([]);

  return (
    <UIPanelList
      {...props}
      scrollBehavior={scrollBehavior}
      onVisibilityChange={(index) => {
        if (activePanels.includes(index)) {
          return;
        }

        setActivePanels((prev) => [...prev, index]);
      }}
      panelList={panelList.map(({ animation, media, solution, ...panel }, index) => ({
        ...panel,
        solution: solution?.slug,
        mediaRatio: media?.mediaRatio || undefined,
        mediaFit: media?.mediaFit || undefined,
        media: (
          <PanelMedia
            animation={animation}
            media={media}
            selected={activePanels.includes(index)}
            offset={scrollBehavior === 'stickyImage'}
          />
        ),
      }))}
    />
  );
}
